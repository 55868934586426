.FooterSection {
    background-color: #000;
}

.FooterTitle h2 {
    color: #fff;
    font-size: 1.2rem;
    font-family: 'Poppins', sans-serif;
    text-transform: uppercase;
    letter-spacing: 0.04rem;
}

.FooterLink {
    display: flex;
    flex-direction: row;
    column-gap: 16px;
    align-items: center;
}

.LinkIcon {
    width: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.EmailIcon {
    width: 33px;
}

.LinkedinIcon {
    width: 26px;
}

.LinkTitle h3 {
    color: #fff;
    font-family: 'Poppins', sans-serif;
    font-weight: 300;
    margin: 12px 0;
}

.LinkTitle h3:hover {
    color: #ffffffde;
    text-decoration: underline;
    text-decoration-color: #0eacf6;
    cursor: pointer;
}