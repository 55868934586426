.ImpactSection {
    background-color: #0eacf6;
    position: relative;
    z-index: -6;
}

.MainContainer {
    /* margin: 0 auto; */
    /* padding: 80px 40px; */
    max-width: 1280px;
}

/* .SectionContent {
    padding: 0 40px;
} */

h2 {
    font-size: 1.8rem;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    letter-spacing: 0.2rem;
}

p {
    font-size: 1.2rem;
    font-family: 'Poppins', sans-serif;
    line-height: 1.9rem;
    font-weight: 300;
    letter-spacing: 0.03rem;
}

.Title {
    color: #fff;
    text-transform: uppercase;
    /* padding-bottom: 32px; */
}

.ImpactSubTitle {
    color: #fff;
    text-transform: uppercase;
}

.ImpactSection p {
    color: #fff;
}

/* .ImpactSection .SectionContent .Content {
    display: flex;
    flex-direction: row;
    column-gap: 12%;
    flex-wrap: wrap;
} */

.ContentColumn {
    /* width: 44%; */
}

.ImpactSubSection {
    flex: 1;
}

.ImpactSubHeader {
    border-bottom: 1px solid #fff;
    display: flex;
    flex-direction: row;
}

.ImpactSubTitle {
    display: flex;
    flex: 9;
}

.ImpactSubIcon {
    display: flex;
    flex: 1;
    flex-direction: row;
    justify-content: end;
}

.ImpactSubContent p {
    /* margin-top: 8px; */
}