.ArchitectureSection {
    background-color: #11537f;
    position: relative;
    z-index: -6;
}

.MainContainer {
    /* margin: 0 auto; */
    /* padding: 80px 40px; */
    max-width: 1280px;
}

/* .SectionContent {
    padding: 0 40px;
} */

.ArchitectureSubTitle {
    color: #fff;
    text-transform: uppercase;
}

.ArchitectureSection p {
    color: #fff;
}

.ArchitectureSubSection {
    flex: 1;
}

.ArchitectureSubHeader {
    border-bottom: 1px solid #fff;
    display: flex;
    flex-direction: row;
}

.ArchitectureSubTitle {
    display: flex;
    flex: 9;
}

.ArchitectureSubIcon {
    display: flex;
    flex: 1;
    flex-direction: row;
    justify-content: end;
}