.Navbar {
    height: 80px;
    max-width: 1280px;
    /* padding: 32px 40px; */
    background: transparent;
    display: flex;
    flex-direction: row;
}

.Logo {
    display: flex;
    align-items: center;
    flex: 2;
}

.Archipelagos-logo {
    max-height: 72px;
    width: auto;
}

.Email {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex: 2;
}

.Email-link {
    /* font-size: 1.4rem; */
    /* font-weight: 500; */
    /* color: #0eacf6; */
    font-family: 'Poppins', sans-serif;
    /* padding: 16px 0; */
    /* text-decoration: none; */
}