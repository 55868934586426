.HeroSection {
    background-color: #0eacf6;
    position: relative;
    z-index: 1;
}

.HeroContent {
    height: 100vh;
    max-height: 720px;
    overflow: hidden;
}

h1 {
    /* font-size: 2.4rem; */
    font-family: 'Poppins', sans-serif;
    /* font-weight: 500; */
    letter-spacing: 0.06rem;
}

.HeroContainer {
    margin: 0 auto;
    /* padding: 0 40px; */
    max-width: 1280px;
    height: 100%;
}

.HeroContainer .SectionContent {
    /* padding: 80px 40px; */
    display: flex;
    flex-direction: column;
    flex-grow: 4;
    justify-content: center;

}

.SectionContent h1 {
    /* margin-bottom: 6px; */
}

.HeroTitle {
    text-align: left;
    text-transform: uppercase;
    color: #fff;
}

h1 span.HeroHighLight {
    color: #0eacf6;
}

.HeroStrapline {
    color: #fff;
}

.HeroStrapline p {
    /* font-size: 1.6rem;
    font-weight: 400; */
    text-transform: uppercase;
    /* line-height: 2.0rem; */
    margin: 0;
}