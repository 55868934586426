.IntroSection {
    background-color: #0eacf6;
    position: relative;
    z-index: -6;
}

.MainContainer {
    /* margin: 0 auto; */
    /* padding: 80px 40px; */
    max-width: 1280px;
}

/* .SectionContent {
    padding: 0 40px;
} */

h2 {
    font-size: 1.8rem;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    letter-spacing: 0.2rem;
}

p {
    font-size: 1.2rem;
    font-family: 'Poppins', sans-serif;
    line-height: 1.9rem;
    font-weight: 300;
    letter-spacing: 0.03rem;
}

.Title {
    color: #fff;
    text-transform: uppercase;
    /* padding-bottom: 32px; */
}

.IntroSection p {
    color: #fff;
    /* columns: 2;
    column-gap: 64px; */
}