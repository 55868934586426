.MainContainer {
    /* margin: 0 auto; */
    /* padding: 80px 40px; */
    max-width: 1280px;
}

/* .SectionContent {
    padding: 0 40px;
} */

.QuoteSection .SectionContent {
    /* width: 63%; */
}

.QuoteIcon {
    /* width: 44px; */
}

.Quote p {
    color: #0eacf6;
    /* font-size: 1.5rem;
    font-weight: 600;
    line-height: 2.2rem; */
    letter-spacing: 0.032rem;
    /* margin-top: 16px; */
}

.Quotee p {
    color: #0eacf6;
    /* font-size: 1.2rem; */
    text-transform: uppercase;
    /* font-weight: 300; */
    letter-spacing: 0.04rem;
}